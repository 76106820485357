.vertical-lr {
  writing-mode: vertical-lr;
}

a:hover {
  color: #f97215;
  cursor: pointer;
}

img {
  -webkit-user-drag: none;
  display: inline-block;
}

.otw {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.otw-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.otw-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.animation-count-1 {
  animation-iteration-count: 1 !important;
  -webkit-animation-iteration-count: 1 !important
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.anticon {
  svg {
    display: block;
  }
}

.my-news-details {
  font-size: 16px;
  color: #646973;

  p {
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    p {
      margin: 10px 0;
    }
  }
}

.my-collapse-style {

  .ant-collapse-header {
    padding: 1.78125rem 0 !important;
    border-bottom: 1px solid #f3f4f6;
    padding-right: 50px !important;
  }

  .ant-collapse-content-box {
    background: #f3f4f6;
    padding: 1.8125rem 1.2rem !important;
    margin-top: -1px;
  }

  @media screen and (max-width: 767px) {
    .ant-collapse-header {
      padding: 0.75rem 0 !important;
      border-bottom: 1px solid #f3f4f6;
      padding-right: 50px !important;
    }

    .ant-collapse-content-box {
      background: #f3f4f6;
      padding: 1rem !important;
      margin-top: -1px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .ant-collapse-header {
      padding: 1rem 0 !important;
      border-bottom: 1px solid #f3f4f6;
      padding-right: 50px !important;
    }

    .ant-collapse-content-box {
      background: #f3f4f6;
      padding: 1.3rem !important;
      margin-top: -1px;
    }
  }
}

.my-textarea {
  .ant-input {
    padding: 15px 20px;
  }
}