/* ./your-css-folder/styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  outline: none;
}

.container {
  @media (min-width: 640px) {}

  @media (min-width: 768px) {}

  @media (min-width: 1024px) {}

  @media (min-width: 1200px) {
    width: 1200px;
  }
}

.spin-loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}